import React, { MouseEvent, useState, useEffect, ReactNode } from "react";
import { NavbarContext } from "./helpers";

const NavbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [drawerExpanded, setDrawerExpanded] = useState(false);
    const [avatarExpanded, setAvatarExpanded] = useState(false);
    const [activeDrawers, setActiveDrawers] = useState(0);

    function onDismiss() {
        setDrawerExpanded(false);
        setAvatarExpanded(false);
    }

    function toggleDrawer(event?: MouseEvent<HTMLButtonElement>) {
        event?.stopPropagation();
        setDrawerExpanded((prev) => !prev);
        setAvatarExpanded(false);
    }

    function toggleAvatar(event?: MouseEvent<HTMLButtonElement>) {
        event?.stopPropagation();
        setAvatarExpanded((prev) => !prev);
        setDrawerExpanded(false);
    }

    useEffect(() => {
        document.addEventListener("click", onDismiss);
        return () => {
            document.removeEventListener("click", onDismiss);
        };
    }, []);

    return (
        <NavbarContext.Provider
            value={{
                setActiveDrawers,
                toggleDrawer,
                toggleAvatar,
                drawerExpanded,
                avatarExpanded,
                activeDrawers,
            }}
        >
            {children}
        </NavbarContext.Provider>
    );
};

export default NavbarProvider;
