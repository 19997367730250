import { getSession } from "next-auth/react";

export type ValidationError = {
    value: string;
    msg: string;
    param: string;
    location: string;
};

export type ServerResponse<T> = {
    code: string;
    message?: string;
    data?: T;
    status: number;
    errors?: ValidationError[];
};

class API {
    private static async headers(incomingToken?: string): Promise<Headers> {
        const headers = new Headers();
        const session = await getSession();
        if (incomingToken) headers.append("Authorization", `Bearer ${incomingToken}`);
        else if (session?.accessToken) headers.append("Authorization", `Bearer ${session?.accessToken}`);
        headers.append("Content-Type", "application/json");
        return headers;
    }

    public static async get<T = void>(
        url: string,
        token?: string
    ): Promise<ServerResponse<T>> {
        const requestOptions = {
            method: "GET",
            headers: await this.headers(token),
        };
        let response: Response | null = null;
        try {
            response = await fetch(
                `${process.env.NEXT_PUBLIC_SERVER}${url}`,
                requestOptions
            );
            return await response.json();
        } catch (error) {
            return {
                code: "500",
                message: "Error fetching data",
                status: 500,
            };
        }
    }

    public static async delete<T = void>(
        url: string,
        body?: unknown | string
    ): Promise<ServerResponse<T>> {
        const requestOptions = {
            method: "DELETE",
            headers: await this.headers(),
            body: typeof body === "string" ? body : JSON.stringify(body),
        };

        let response: Response | null = null;
        try {
            response = await fetch(
                `${process.env.NEXT_PUBLIC_SERVER}${url}`,
                requestOptions
            );
            return await response.json();
        } catch (error) {
            return {
                code: "500",
                message: "Error fetching data",
                status: 500,
            };
        }
    }

    public static async post<T = void>(
        url: string,
        body?: unknown | string
    ): Promise<ServerResponse<T>> {
        const requestOptions = {
            method: "POST",
            headers: await this.headers(),
            body: typeof body === "string" ? body : JSON.stringify(body),
        };

        let response: Response | null = null;
        try {
            response = await fetch(
                `${process.env.NEXT_PUBLIC_SERVER}${url}`,
                requestOptions
            );
            return await response.json();
        } catch (error) {
            return {
                code: "500",
                message: "Error fetching data",
                status: 500,
            };
        }
    }

    public static async put<T = void>(
        url: string,
        body?: unknown | string
    ): Promise<ServerResponse<T>> {
        const requestOptions = {
            method: "PUT",
            headers: await this.headers(),
            body: typeof body === "string" ? body : JSON.stringify(body),
        };

        let response: Response | null = null;
        try {
            response = await fetch(
                `${process.env.NEXT_PUBLIC_SERVER}${url}`,
                requestOptions
            );
            return await response.json();
        } catch (error) {
            return {
                code: "500",
                message: "Error fetching data",
                status: 500,
            };
        }
    }
}

export default API;
