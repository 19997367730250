import Link from "next/link";
import { DetailedHTMLProps, HTMLAttributes } from "react";

export interface TuturnoBrandProps
    extends DetailedHTMLProps<
        HTMLAttributes<HTMLHeadingElement>,
        HTMLHeadingElement
    > {
    className?: string;
    lightText?: boolean;
}

export const TuturnoBrand: React.FC<TuturnoBrandProps> = ({
    className,
    lightText,
}) => {
    return (
        <Link
            href="/"
            className={`navbar-brand p-0 m-0 fw-bold text-center ${className}`}
        >
            <span className="text-primary h4 fw-bold">TU</span>
            <span
                className={`${
                    lightText ? "text-white" : "text-dark"
                } h4 fw-bold`}
            >
                TURNO
            </span>
        </Link>
    );
};

export default TuturnoBrand;
