import API from "helpers/API";
import useUser, { LoadableUser } from "helpers/useUser";
import { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { FormData } from "types/FormData";

interface AccountForm
    extends Omit<UseFormReturn<AccountData>, "handleSubmit" | "control"> {
    onSubmit(): void;
    user: LoadableUser;
}

export interface AccountData extends FormData {
    name?: string;
    phone?: string;
}

const useAccountForm = (): AccountForm => {
    const user = useUser();
    const { handleSubmit, ...formHook } = useForm<AccountData>();
    const onSubmit = handleSubmit(async (data) => {
        const response = await API.put("/api/me", data);
        if (response.code !== "200") {
            alert(response.message);
        } else {
            alert("¡Tus datos se actualizaron correctamente!");
            window.location.reload();
        }
    });

    useEffect(() => {
        formHook.setValue("name", user.name);
        if (user.phone?.startsWith("+")) {
            formHook.setValue("phone", user.phone);
        } else {
            formHook.setValue("phone", "+" + user.phone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.name, user.phone]);

    return {
        ...formHook,
        onSubmit,
        user,
    };
};

export default useAccountForm;
