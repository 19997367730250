import API from "helpers/API";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

export interface SendVerificationMail {
    onSubmit(): void;
}

const useSendVerificationMailForm = (): SendVerificationMail => {
    const form = useForm();

    const onSubmit = form.handleSubmit(async () => {
        const response = await API.get(`/api/me/request-verification-email`);
        if (response.code === "200") {
            toast(
                "Hemos enviado un email de verificación a tu casilla de correo. Por favor revísala.",
                { duration: 4000, position: "top-right", icon: "✅" }
            );
        } else {
            console.error(response);
            alert(response.message);
        }
    });

    return { ...form, onSubmit };
};

export default useSendVerificationMailForm;
