import Script from "next/script";
import { useEffect, useState } from "react";

const HubSpotChatLoader: React.FC = () => {
    const [initHubspot, setInitHubspot] = useState(false);
        
    useEffect(() => {
        const timeout = setTimeout(() => {
            setInitHubspot(true);
        },5000);

        return () => {
            clearTimeout(timeout);
        }
    },[]);


    if (initHubspot) {
        return (
            <Script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src="//js-eu1.hs-scripts.com/26196886.js"
            />
        );
    } else return null;
};

export default HubSpotChatLoader;
