import React, {
    useCallback,
    useState,
    createContext,
    useContext,
    useEffect,
    ReactNode,
} from "react";
import { useRouter } from "next/router";
import User from "types/User";
import API from "./API";
import { getSession, signOut } from "next-auth/react";
import Business from "types/Business";

export interface LoadableUser
    extends Partial<
        User & { businesses: Business[]; unconfirmedAppointments: number }
    > {
    /** Reloads the user data */
    reload(): Promise<void>;
    /** True if the user data is been loaded. */
    loading: boolean;
    /** Log out the user */
    logout(): Promise<void>;
    /** Wether the user is logged or not. */
    logged: boolean;
}

export const UserContext = createContext<LoadableUser>({
    reload: () =>
        new Promise((res) => {
            console.error(`reload method not implemented.`);
            res();
        }),
    logout: async () => console.error(`logout method not implemented.`),
    loading: true,
    logged: false,
});

const useUser = (): LoadableUser => {
    return useContext(UserContext);
};

export const UserProvider: React.FC<{ children?: ReactNode }> = (props) => {
    const [user, setUser] = useState<
        User & { businesses: Business[]; unconfirmedAppointments: number }
    >();
    const [loading, setLoading] = useState(true);
    const router = useRouter();
    const [logged, setLogged] = useState(false);

    const logout = useCallback(async () => {
        await signOut({ redirect: false });
        await router.push("/login");
        window.location.reload();
    }, [router]);

    const reload = useCallback(async () => {
        setLoading(true);
        try {
            const response = await API.get<
                User & {
                    businesses: Business[];
                    unconfirmedAppointments: number;
                }
            >("/api/me");
            if (response.code === "200") {
                setUser(response.data);
                setLogged(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            const session = await getSession();
            if (session?.accessToken) reload();
            else setLoading(false);
        })();
    }, [reload]);

    return (
        <UserContext.Provider
            value={{ logout, reload, loading, logged, ...user }}
            {...props}
        />
    );
};

export default useUser;
