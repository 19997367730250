import { ButtonHTMLAttributes } from "react";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
    disabled?: boolean;
    secondary?: boolean;
    text: string;
}

const Button: React.FC<ButtonProps> = ({
    text,
    loading,
    disabled,
    className,
    secondary,
    ...props
}) => {
    return (
        <button
            disabled={loading || disabled}
            type="button"
            className={`btn 
				${secondary ? "btn-secondary" : "btn-primary"} 
				${className ? className : ""}
			`}
            {...props}
        >
            {loading && (
                <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                />
            )}
            {text}
        </button>
    );
};

export default Button;
