import { MouseEvent, createContext, useContext } from "react";

export type NavbarContextType = {
    toggleDrawer?(event?: MouseEvent<HTMLButtonElement>): void;
    toggleAvatar?(event?: MouseEvent<HTMLButtonElement>): void;
    setActiveDrawers?: React.Dispatch<React.SetStateAction<number>>;
    drawerExpanded?: boolean;
    avatarExpanded?: boolean;
    activeDrawers?: number;
};

export const NavbarContext = createContext<NavbarContextType>({});
export const useNavbar = () => useContext(NavbarContext);
