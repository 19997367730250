import React from "react";
import Button from "@tuturno/tuturno-ui/components/Button";
import Link from "next/link";
import toast from "react-hot-toast";
import useAccountForm from "pages/me/useAccountForm";
import useSendVerificationMailForm from "pages/me/useSendVerificationMail";

const RegisterBusinessButton = () => {
    const form = useAccountForm();
    const sendVerificationMailForm = useSendVerificationMailForm();
    return (
        <div className="me-lg-5 mb-3">
            <h6 className="text-light mb-0 mb-1">SUMATE</h6>
            {!form.user.logged ? (
                <Link
                    href={`/login?comeBack=${encodeURIComponent("/register-business")}`}
                    className="text-decoration-none text-light fw-light"
                >
                    Registrá tu negocio
                </Link>
            ) : form.user.logged &&
              !form.user.loading &&
              !form?.user?.emailConfirmed ? (
                <div>
                    <a
                        className="text-decoration-none text-light fw-light"
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            toast.custom(
                                <form
                                    onSubmit={sendVerificationMailForm.onSubmit}
                                    className="bg-light w-25 d-flex shadow-sm"
                                    style={{
                                        borderRadius: 20,
                                        padding: 10,
                                    }}
                                >
                                    <div className="col-2 align-content-center  justify-content-center my-auto mx-auto text-center ">
                                        ✅
                                    </div>
                                    <div className="col-10">
                                        <span>
                                            Su correo electrónico aún no fue
                                            verificado. Recuerde que su negocio
                                            no será visible al público hasta que
                                            no realice la validación. Por favor,
                                            revise su bandeja de entrada. Si no
                                            lo encuentra, puede solicitar otro
                                            correo de confirmación haciendo clic
                                            aquí 👉
                                            <Button
                                                type="submit"
                                                className="mx-2"
                                                loading={
                                                    form.formState.isSubmitting
                                                }
                                                text="click"
                                            />
                                            (recuerde revisar su carpeta de
                                            spam).
                                        </span>
                                    </div>
                                </form>,
                                {
                                    duration: 10000,
                                    position: "top-right",
                                }
                            );
                        }}
                    >
                        Registrá tu negocio
                    </a>
                </div>
            ) : (
                <Link
                    href="/register-business"
                    className="text-decoration-none text-light fw-light"
                >
                    Registrá tu negocio gratis
                </Link>
            )}
        </div>
    );
};

export default RegisterBusinessButton;
