import TuturnoBrand from "components/TuturnoBrand";
import Link from "next/link";
import React, { Fragment } from "react";
import RegisterBusinessButton from "./RegisterBusinessButton";

const Footer: React.FC = () => {
    return (
        <Fragment>
            <div className="bg-info">
                <div className="container">
                    <div
                        className={`d-flex py-4 flex-column-reverse flex-lg-row justify-content-center justify-content-lg-between align-items-center text-center text-lg-start`}
                    >
                        <div className="d-flex flex-column flex-lg-row">
                            <div className="me-lg-5 mb-3">
                                <h6 className="text-light mb-1">NOSOTROS</h6>
                                <Link
                                    href="/about"
                                    className="text-decoration-none text-light fw-light"
                                >
                                    Quienes somos
                                </Link>
                            </div>
                            <div className="me-lg-5 mb-3">
                                <h6 className="text-light mb-0 mb-1">
                                    CONTACTO
                                </h6>
                                <Link
                                    href="mailto:hola@tuturno.com.ar"
                                    className="text-decoration-none text-light fw-light"
                                >
                                    hola@tuturno.com.ar
                                </Link>
                            </div>
                            <div className="me-lg-5 mb-3">
                                <h6 className="text-light mb-0 mb-1">LEGAL</h6>
                                <Link
                                    href="/privacy"
                                    className="text-decoration-none text-light fw-light"
                                >
                                    Ver políticas de privacidad
                                </Link>
                            </div>
                            <RegisterBusinessButton />
                        </div>
                        <TuturnoBrand
                            className="ms-lg-5 mb-3"
                            lightText={true}
                        />
                    </div>
                </div>
            </div>
            <div className="bg-muted text-light fw-light text-center p-2">
                <small>
                    © 2021-2024, Tuturno. Todos los derechos reservados.
                </small>
            </div>
        </Fragment>
    );
};

export default Footer;
