import "@tuturno/tuturno-ui/custom.scss";
import "./hubspot.css";
import { GoogleAnalytics } from "@next/third-parties/google";
import type { AppProps } from "next/app";
import { Fragment } from "react";
import NavbarProvider from "@tuturno/tuturno-ui/components/Navbar/NavbarProvider";
import { UserProvider } from "helpers/useUser";
import Footer from "components/Footer";
import Head from "next/head";
import HubSpotChatLoader from "components/HubSpotChatLoader";
import { Toaster } from "react-hot-toast";

function MyApp({ Component, pageProps }: AppProps) {
    return (
        <Fragment>
            <Head>
                <meta name="application-name" content="Tuturno" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta
                    name="apple-mobile-web-app-status-bar-style"
                    content="default"
                />
                <meta name="apple-mobile-web-app-title" content="Tuturno" />
                <meta
                    name="description"
                    content="Plataforma online para que cualquier organización pueda ofrecer sus servicios."
                />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="theme-color" content="#c41c5f" />

                <link rel="apple-touch-icon" href="/logo/logo.png" />
                <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href="/logo/logo.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/logo/logo.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="167x167"
                    href="/logo/logo.png"
                />

                <link
                    rel="icon"
                    type="image/png"
                    sizes="48x48"
                    href="/logo/logo48.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="128x128"
                    href="/logo128.png"
                />
                <link rel="manifest" href="/manifest.json" />
                <link rel="shortcut icon" href="/favicon.ico" />

                <meta
                    name="twitter:card"
                    content="summary"
                    key="twitter:card"
                />
                <meta
                    name="twitter:url"
                    content="https://tuturno.com.ar"
                    key="twitter:url"
                />
                <meta
                    name="twitter:title"
                    content="Tuturno"
                    key="twitter:title"
                />
                <meta
                    name="twitter:description"
                    content="Plataforma de turnos online"
                    key="twitter:description"
                />
                <meta
                    name="twitter:image"
                    content="https://tuturno.com.ar/logo192.png"
                    key="twitter:image"
                />
                <meta
                    name="twitter:creator"
                    content="@tuturno"
                    key="twitter:creator"
                />
                <meta property="og:type" content="website" key="og:type" />
                <meta property="og:title" content="Tuturno" key="og:title" />
                <meta
                    property="og:description"
                    content="Plataforma de turnos online"
                    key="og:description"
                />
                <meta
                    property="og:site_name"
                    content="Tuturno"
                    key="og:site_name"
                />
                <meta
                    property="og:url"
                    content="https://tuturno.com.ar"
                    key="og:url"
                />
                <meta
                    property="og:image"
                    content="https://tuturno.com.ar/logo/logo48.png"
                    key="og:image"
                />
            </Head>
            <UserProvider>
                <NavbarProvider>
                    <div suppressHydrationWarning>
                        <Component {...pageProps} />
                    </div>
                    <Footer />
                    <Toaster />
                </NavbarProvider>
            </UserProvider>
            <HubSpotChatLoader />
            {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && (
                <GoogleAnalytics
                    gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}
                />
            )}
        </Fragment>
    );
}

export default MyApp;
